import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { gatedSectionsv2 } from "../../../data/landing/gated-v2";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import { NewBusinessTypes } from "../../../data/common-data";
import { zipValidationRegExp } from "../../../constants";
// import GatedForm from "../../../components/CustomGated/GatedForm";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import Image from "../../../components/ImageQuerys/LargeFeaturesImages";
// import GatedCTA from "../../../components/CustomGated/GatedCTA";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedCTA = loadable(() =>
  import("../../../components/CustomGated/GatedCTA")
);

const formDataToSend = [
  "firstname",
  "email",
  "phone",
  "company",
  "get_demo",
  "gated_campaign",
];
const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.DROPDOWN,
    itemSubType: null,
    label: "Industry",
    name: "industry",
    rules: [
      {
        required: true,
        message: "Please enter the type of the project",
      },
    ],
    placeholder: "Select your industry",
    options: NewBusinessTypes,
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv3 = () => {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Whitepapers | Point-of-Sale E-Book"
        description="Boost efficiency, increase sales, and keep guests coming back with a single point-of-sale solution for in-person orders, Order with Google, and online ordering"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row gutter={[32, 32]}>
        <Col lg={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              padding: "3%",
            }}
          >
            <div className="gate-heading-v3">
              <Heading injectionType={2} level={1}>
                Building customer relationships with a well-defined brand 👉
              </Heading>
              <p className="gated-intro-para ">
                Do you know how to manage your brand online? Your brand is the
                first impression that potential customers have of your business.
                Learn how to build, manage, and evolve it—and how technology can
                make it easier.
              </p>
            </div>
          </div>
        </Col>

        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              formTitle="Get your Free whitepaper here"
              formItems={data}
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formDataToSend={formDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free copy today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/online-brand-customer-relationships/thank-you"
              gatedCampaign="online-brand-customer-relationships"
              withLineOfBusinessOption
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24}>
          <LargeFeatures sectionData={gatedSectionsv2} />
        </Col>
      </Row>
      <GatedCTA />
    </LpLayout>
  );
};

export default GatedAssetv3;
